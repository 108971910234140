/* eslint-disable import/order */
import '@/@iconify/icons-bundle'
import App from '@/App.vue'
import ability from '@/plugins/casl/ability'
import i18n from '@/plugins/i18n'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/router'
import { abilitiesPlugin } from '@casl/vue'
import '@core/scss/template/index.scss'
import '@styles/styles.scss'
import { createPinia } from 'pinia'
import VTooltip from 'v-tooltip'
import { createApp } from 'vue'
import store from './pages/store'


loadFonts()


// Create vue app
const app = createApp(App)


// Use plugins
app.use(vuetify)
app.use(createPinia())
app.use(router)
app.use(layoutsPlugin)
app.use(i18n)
app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
})
app.use(store);
app.use(VTooltip)

//app.use(cors());


import socket from '../src/server/websocket'
const userDataJSON = localStorage.getItem('userData');
const userData = userDataJSON ? JSON.parse(userDataJSON) : null;

// socket.on('accountDeactivated', (data) => {
//   const receivedEmail = data.email;
//   console.log('receivedEmail outside condition', receivedEmail, userData);
// });

if (userData) {
  socket.on('accountDeactivated', (data) => {
    const receivedEmail = data.email;
    if (userData.email === receivedEmail) {
      logout();
    }
  });
} else {
  router.push('/login');
}

// import screens from './pages/dashboards/screens.vue'
// socket.on('screenIsactive', (data) => {
//   console.log('DATA COME BY screenIsactive ');
//   console.log('data.msg',data.message);
//   console.log('data.msg',data.tenantId);
//   if (data) {
//     screens.getAllScreens();
//   }
// })

const logout = () => {
  // Remove "userData" from localStorage
  localStorage.removeItem('userData');

  // Remove "accessToken" from localStorage
  localStorage.removeItem('accessToken');

  // Remove "userAbilities" from localStorage
  localStorage.removeItem('userAbilities');

  // Clear the entire localStorage
  // localStorage.clear();

  // Reset ability to initial ability
  // ability.update(initialAbility);

  //router.push('/login');

};

// Mount vue app
app.mount('#app')
