
import { io } from 'socket.io-client';


//const socket = io('http://127.0.0.1:5050');107.155.65.19,209.182.233.252
const userData = JSON.parse(localStorage.getItem('userData'))
let socket = '';

// Dev 
// const ipAddress = "http://209.182.233.252:3000"

// Production
const ipAddress = "https://app.mediawall.in:3001"


if (userData) {
    socket = io(ipAddress, {
        transportOptions: {
            polling: {
                extraHeaders: {
                    username: userData.username
                }
            }
        }
    })
} else {
    socket = io(ipAddress);
}


export default socket;