// import { setupLayouts } from 'virtual:generated-layouts';
// import { createRouter, createWebHistory } from 'vue-router';
// import routes from '~pages';
// import { isUserLoggedIn } from './utils';

// const router = createRouter({
//   history: createWebHistory(import.meta.env.BASE_URL),
//   routes: [
//     {
//       path: '/',
//       redirect: (to) => {
//         const userData = JSON.parse(localStorage.getItem('userData') || '{}');
//         const userRole = userData?.role || null;
//         const userName = userData?.organizationName ? userData?.organizationName?.toLowerCase() : null || null;
//         // const userName = userData?.username && userData?.organizationName
//         //   ? `${userData?.organizationName?.toLowerCase()}/${userData?.username?.toLowerCase()}`
//         //   : null;


//         if (!userName) {
//           return { name: 'login', query: to.query }; // Redirect to login if username is missing
//         }

//         if (userRole === 'admin' || userRole === 'user' || userRole === 'author' || userRole === 'manager') {
//           return { name: 'dashboards-adminDashboard', params: { name: userName } };
//         }
//         if (userRole === 'reseller') {
//           return { name: 'dashboards-resellerDashboard', params: { name: userName } };
//         }
//         if (userRole === 'superAdmin') {
//           return { name: 'superAdmin-components-clientsSection', params: { name: userName } };
//         }

//         return { name: 'login', query: to.query }; // Default to login
//       },
//     },
//     ...setupLayouts(
//       routes.map((route) => {
//         // Only prepend `/:name` to routes that require it
//         if (!['/login', '/not-authorized', '/register', '/forgot-password'].includes(route.path)) {
//           if (!route.path.startsWith('/:name')) {
//             // console.log('FINAL IF CONDITIOM');

//             route.path = '/:name' + route.path;
//           }
//         }
//         return route;
//       }),
//     ),
//   ],
// });


// // Navigation guard
// router.beforeEach((to, from) => {
//   const userData = JSON.parse(localStorage.getItem('userData') || '{}');
//   const isLoggedIn = isUserLoggedIn();
//   const userName = userData?.username ? userData?.username?.toLowerCase() : null || null;
//   const userRole = userData?.role || null;

//   // Handle routes that do not require `:name`
//   const noNameRoutes = ['login', 'not-authorized', 'register', 'forgot-password'];
//   if (noNameRoutes.includes(to.name)) {
//     return true; // Allow navigation
//   }

//   // Check if the user is logged in and has a username
//   if (!isLoggedIn || !userName) {
//     return { name: 'login', query: { to: to.fullPath } }; // Redirect to login if unauthenticated or no username
//   }

//   // Restrict unauthorized access based on role
//   if (userRole !== 'superAdmin' && to.path.includes('/superadmin')) {
//     return { name: 'not-authorized' };
//   }

//   // Add `:name` to route params if missing
//   if (!to.params.name) {
//     return { ...to, params: { ...to.params, name: userName } };
//   }

//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     return { name: 'dashboards-adminDashboard', params: { name: userName } };
//   }

//   return true; // Allow navigation
// });

// export default router;



import { setupLayouts } from 'virtual:generated-layouts';
import { createRouter, createWebHistory } from 'vue-router';
import routes from '~pages';
import { isUserLoggedIn } from './utils';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: (to) => {
        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        const userRole = userData?.role || null;
        const userName = userData?.organizationName
          ? userData?.organizationName?.toLowerCase()
          : userData?.username?.toLowerCase() || null;

        if (!userName) {
          return { name: 'login', query: to.query }; // Redirect to login if username is missing
        }

        if (userRole === 'admin' || userRole === 'user' || userRole === 'author' || userRole === 'manager') {
          return { name: 'dashboards-adminDashboard', params: { name: userName } };
        }
        if (userRole === 'reseller') {
          return { name: 'dashboards-resellerDashboard', params: { name: userName } };
        }
        if (userRole === 'superAdmin') {
          return { name: 'superAdmin-components-clientsSection', params: { name: userName } };
        }

        return { name: 'login', query: to.query }; // Default to login
      },
    },
    {
      path: '/:name',
      redirect: (to) => {
        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        const userRole = userData?.role || null;

        if (userRole === 'admin' || userRole === 'user' || userRole === 'author' || userRole === 'manager') {
          return { name: 'dashboards-adminDashboard', params: { name: to.params.name } };
        }
        if (userRole === 'reseller') {
          return { name: 'dashboards-resellerDashboard', params: { name: to.params.name } };
        }
        if (userRole === 'superAdmin') {
          return { name: 'superAdmin-components-clientsSection', params: { name: to.params.name } };
        }

        return { name: 'login', query: to.query }; // Default to login
      },
    },
    ...setupLayouts(
      routes.map((route) => {
        if (!['/login', '/not-authorized', '/register', '/forgot-password'].includes(route.path)) {
          if (!route.path.startsWith('/:name')) {
            route.path = '/:name' + route.path;
          }
        }
        return route;
      }),
    ),
  ],
});

// Navigation guard
router.beforeEach((to, from) => {
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const isLoggedIn = isUserLoggedIn();
  const userName = userData?.organizationName
    ? userData?.organizationName?.toLowerCase()
    : userData?.username?.toLowerCase() || null;

  const userRole = userData?.role || null;

  // Handle routes that do not require :name
  const noNameRoutes = ['login', 'not-authorized', 'register', 'forgot-password'];
  if (noNameRoutes.includes(to.name)) {
    return true; // Allow navigation
  }

  // Check if the user is logged in and has a username
  if (!isLoggedIn || !userName) {
    return { name: 'login', query: { to: to.fullPath } }; // Redirect to login if unauthenticated or no username
  }

  // Restrict unauthorized access based on role
  if (userRole !== 'superAdmin' && to.path.includes('/superadmin')) {
    return { name: 'not-authorized' };
  }

  // Add :name to route params if missing
  if (!to.params.name) {
    return { ...to, params: { ...to.params, name: userName } };
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return { name: 'dashboards-adminDashboard', params: { name: userName } };
  }

  return true; // Allow navigation
});

export default router;
